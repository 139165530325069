<template>
  <docs-layout class="text-left">
    <p class="mb-4">Refering to <asom-link href="https://tailwindcss.com/docs/grid-template-columns" target="blank">TailwindCSS documentation</asom-link> to learn more about grid system</p>
    <p>We don't have any concrete styling rules for grid system yet, so let's stick with a few based on TailwindCSS example:</p>
    <ul class="list-inside list-disc mt-2">
      <li>Always specify at least 2 column widths: 1 for medium screen size (<pre class="contents">md</pre>) and 1 for lower resolution</li>
      <li>Use gap size 4 for our grids</li>
    </ul>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: { DocsLayout }
}
</script>